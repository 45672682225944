import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import { Link } from 'gatsby';
import Button from '../components/Button';
// import imageHeader from '../../img/small-speaker.png';
// import imageHosokawa from '../../static/img/D1qfcETWsAAqU-h.jpg';
// import imageListeningBar from '../../static/img/listening-bar.png';
// import imageConcert from '../../static/img/concert-transparent.png';
// import imagePhotographers from '../../static/img/theme-3-insight-9.png';
// import imageAtypical1 from '../../static/img/atypical1.png';
// import imageAtypical2 from '../../static/img/atypical2.png';
// import imageAtypical3 from '../../static/img/atypical3.png';
// import imageDifficult1 from '../../static/img/difficult1.png';
// import imageDifficult2 from '../../static/img/difficult2.png';

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <body className="page--intro" />
        </Helmet>
        <div className="articleWrapper articleType--intro">
          <header className="pageHeader pageHeader--hasImg grid--narrow">
            <div className="pageHeader__image">
              <div className="imageWrapper">
                <img src={'../../img/small-speaker.png'} alt="Large audio speaker" />;
              </div>
            </div>
            <div className="pageHeader__content">
              <h1>Music culture &amp; Fandom in Japan</h1>
            </div>
          </header>
          <main className="pageMain">

            {/* <section className="s s--imageCaptionMinimal">
              <div className="s__inner">
                <div className="col col--image">
                  <div className="imageWrapper">
                    <img src={'../../img/D1qfcETWsAAqU-h.jpg'} alt="Man sitting on the floor surrounded by dozens of guitar pedals and amps" />;
                  </div>
                </div>
                <div className="col col--caption">
                  <p>細川雄一郎<br />Yuichiro Hosokawa<br />Twitter: <a href="https://twitter.com/hosokaw_" target="_blank" rel="noreferrer">@hosokaw_</a><br />Tokyo</p>
                  <p>Collecting music paraphernalia - from cassette recorders and synths to guitar pedals - is a way for music enthusiasts to express identity, cultivate expertise, and connect with the past</p>
                </div>
              </div>
            </section> */}

            <section className="s s--sideBySide">
              <div className="s__inner">
                <div className="col col--image">
                  <div className="imageWrapper">
                    <img src={'../../img/listening-bar.png'} alt="Japanese woman in a listening bar, surrounded by other music listeners, smiling" />
                  </div>
                </div>
                <div className="col col--content">
                  <h4>The challenge</h4>
                  <h2>Spotify is looking to differentiate their offering and accelerate sticky user growth with new &amp; existing music streamers in Japan.</h2>
                  <h4>Our objective</h4>
                  <p>For Spotify to unlock new experiences tailored to audiences in Japan, we set out to understand the social contexts in which music consumption gains meaning and value.</p>
                </div>
              </div>
            </section>

            <section className="s s--list">
              <div className="s__inner">
                <div className="s__heading">
                  <h4>Our Approach</h4>
                </div>
                <div className="list">
                  <div className="listItem">
                    <h6>Desk Research</h6>
                    <p>We established a broad foundational understanding of cultural context, industry momentum, &amp; contemporary practices. This research touched on the fandom behaviors, industry dynamics, and relevant pieces of greater cultural context.</p>
                  </div>
                  <div className="listItem">
                    <h6>Expert Interviews</h6>
                    <p>We spoke to Japanese music industry executives/experts, academics focused on contemporary fandom in Japan as well as members of Spotify&apos;s Japan operations to get insider takes on the forces at play in the local music industry and scene.</p>
                  </div>
                  <div className="listItem">
                    <h6>User Interviews</h6>
                    <p>We also conducted in-depth interviews with 10 Japanese music fans, spanning a mix of streaming users and considers as well as a mix of medium to highly engaged fans. This ethnographic immersion gave us deeper insight into streaming users&apos; needs, motivations, beliefs and behaviors.</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="s s--centered">
              <div className="s__inner">
                <div className="s__heading">
                  <h4>Key Context</h4>
                </div>
                <h1>Japan, the world&apos;s second largest music industry, is distinct from other markets.</h1>
              </div>
            </section>

            <section className="s s--sideBySide">
              <div className="s__inner">
                <div className="col col--image">
                  <div className="imageWrapper">
                    <img src={'../../img/concert-transparent.png'} alt="People in a crowded live concert" />
                  </div>
                </div>
                <div className="col col--content flex-column flex--vAlignCenter">
                  <p>
                    People [in the business] are used to seeing "mature" markets like Germany or "emerging" markets like Indonesia and India. But Japan is neither of those… it's rich with high-growth prospects because it's been behind for so long.”
                  </p>
                  <p className="quoteAuthor">&mdash; Tony Elison, Spotify Japan</p>
                </div>
              </div>
            </section>

            <section className="s s--list">
              <div className="s__inner">
                <div className="s__heading s__heading--narrow">
                  <h2>Japan&apos;s atypical music scene is highly restrained and controlled.</h2>
                </div>
                <div className="list">
                  <div className="listItem">
                    <h6>Management agencies <em>(jimusho)</em> reign supreme, restricting artists and their relationships with fans.</h6>
                    <p>Japan's music industry operates through a restrictive system of private management companies called <em>jimusho</em>, which scout &amp; produce artists, and <strong>control their creative expression</strong>, branding and interaction with fans.</p>
                  </div>
                  <div className="listItem">
                    <h6>Regulatory bodies retain strict control over the ways music is distributed, performed and consumed.</h6>
                    <p>The Japanese Society for Rights of Authors, Composers and Publishers, controls 98% of music copyrights and wields <strong>strict control over how music is consumed</strong> in public and commercial spaces within Japan.</p>
                  </div>
                  <div className="listItem">
                    <h6>Physical music products (like CDs, vinyl) are still popular formats of music consumption.</h6>
                    <p>An emphasis on material culture and collectibility as well as a government-backed saihan (price control) system has kept <strong>CDs as the primary driver of revenue</strong> in the Japanese music industry.</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="s s--sideBySide">
              <div className="s__inner">
                <div className="s__heading s__heading--narrow">
                  <h2>Japanese culture emphasizes respecting boundaries and refraining from imposing on others.</h2>
                </div>
                <div className="col col--content col--padded">
                  <p className="quote">
                    &ldquo;It wouldn't do to pry into [artists'] private lives, especially for indie artists who also work other jobs. It wouldn't be right to interact with them if you just happened to see them somewhere.”
                  </p>
                  <p className="quoteAuthor">&mdash;  Taro, Streaming Considerer</p>
                </div>
                <div className="col col--content">
                  <p className="lead">In the world of music, many official fan groups stipulate extensive guidelines for how fans should interact with artists, emphasizing the importance of respect. </p>
                </div>
              </div>
            </section>

            <section className="s s--centered">
              <div className="s__inner">
                <h1>Fans have to navigate the strict rules and social codes of this restricted context.</h1>
              </div>
            </section>

            <section className="s s--sideBySide">
              <div className="s__inner">
                <div className="col col--content flex-column flex--vAlignCenter">
                  <p>The term ‘fan’ is too broad &amp; generic to capture the diverse range of behaviors that Japanese music consumers exhibit.</p>
                  <p>We spoke to a variety of people - including fervent artist devotees, casual music listeners and active members of fan communities - to understand how people navigate this unique context.</p>
                </div>
                <div className="col col--image">
                  <div className="imageWrapper">
                    <img src={'../../img/theme-3-insight-9.png'} alt="Dozens of photographers pointing their cameras at a group of artists" />
                  </div>
                </div>
              </div>
            </section>

            <section className="s s--group theme--dark">

            <section className="ss ss--centered">
              <div className="s__inner">
                <h1>How do streaming services fit into this?</h1>
              </div>
            </section>

              <section className="ss s--sideBySide">
                <div className="s__inner">
                  <div className="s__heading s__heading--narrow">
                    <h2>Streaming is facing a ‘trust’ problem in Japan</h2>
                  </div>
                  <div className="col col--content">
                    <p>Many Japanese music consumers are reluctant to change their established preferences or to ‘fix’ something that isn’t broken. </p>
                    <p>Japan is one of the most highly ranked countries in the world for ‘Uncertainty Avoidance’ meaning new, shiny or disruptive isn’t seen as inherently good. New products, services and brands have to prove their worth before consumers are willing to trust them.</p>
                  </div>
                  <div className="col col--content">
                    <p className="lead quote">
                      &ldquo;I’m happy with the status quo. If I lost all my downloaded music or something then I might change how I listen to music, but for now I am happy with the status quo.&rdquo;
                    </p>
                    <p className="quoteAuthor">&mdash; Genzo, Streaming Considerer</p>
                  </div>
                </div>
              </section>{/* subsection end */}

              <section className="ss s--list">
                <div className="s__inner">
                  <div className="s__heading s__heading--narrow">
                    <h2>Japanese listeners believe streaming services hinder connection to artists and other fans, while insufficiently compensating artists.</h2>
                  </div>
                  <div className="list">
                      <div className="listItem">
                      <div className="image">
                        <div className="imageWrapper">
                          <img src={'../../img/atypical1.png'} alt="Illustration of a man with headphones smiling and receiving praise and money from fans" />
                        </div>
                      </div>
                      <h4>Streaming ≠ Support: Users worry artists aren’t fairly compensated by streaming services</h4>
                      <p className="quote"><strong>&ldquo;Streaming doesn't really help the artist make money.</strong> Artists themselves ask us to buy their CDs rather than stream their music, which doesn't give them much at all.&rdquo;</p>
                      <p className="quoteAuthor">Taro</p>
                    </div>
                    <div className="listItem">
                      <div className="image">
                        <div className="imageWrapper">
                          <img src={'../../img/atypical2.png'} alt="A woman with headphones and eyes shut" />
                        </div>
                      </div>
                      <h4>Streaming ≠ Connection: Users feel a lack of connection to artists and fans on streaming services</h4>
                      <p className="quote"><strong>&ldquo;I don't feel a direct connection with artists through streaming music</strong> on music apps. And I've never encountered a feature in music streaming apps that made me feel it was easier to connect with other fans or artists.&rdquo;</p>
                      <p className="quoteAuthor">&mdash; Kanako</p>
                    </div>
                    <div className="listItem">
                      <div className="image">
                        <div className="imageWrapper">
                          <img src={'../../img/atypical3.png'} alt="Screen capture of an error message from the Spotify app saying No results found for" />
                        </div>
                      </div>
                      <h4>Streaming ≠ Availability: Users believe streaming services have limited music catalogues</h4>
                      <p className="quote">&ldquo;I wish Johnny’s artists would be covered by streaming services. Then I wouldn’t have to buy CDs.&rdquo;</p>
                      <p className="quoteAuthor">&mdash; Aya</p>
                    </div>
                  </div>
                </div>
              </section>{/* subsection end */}

              <section className="ss s--list">
                <div className="s__inner">
                  <div className="s__heading s__heading--narrow">
                    <h2>While many users admire Spotify’s superior sound quality, some find it difficult to use</h2>
                  </div>
                  <div className="list">
                      <div className="listItem">
                      <div className="image">
                        <div className="imageWrapper">
                          <img src={'../../img/difficult1.png'} alt="Screen capture showing an error message from an iPhone saying Login failed." />
                        </div>
                      </div>
                      <h4>Difficult to Use: Many streaming users find Spotify difficult to navigate (especially during the sign-up process)</h4>
                      <p className="quote">&ldquo;I used the free version about 1 year ago but I couldn't really figure out how to use it and it didn't feel good to me, so I deleted it. I found its downloads slow, and I felt its top page was hard to navigate as the thumbnails were very big.&rdquo;</p>
                      <p className="quoteAuthor">&mdash; TH</p>
                    </div>
                    <div className="listItem">
                      <div className="image">
                        <div className="imageWrapper">
                          <img src={'../../img/difficult2.png'} alt="A Japanese man holding an old record with a large collection of records in the background" />
                        </div>
                      </div>
                      <h4>Made for Audiophiles: Many streaming users perceive Spotify’s superior audio quality to be for music specialists</h4>
                      <p className="quote">&ldquo;I think Spotify is the specialist and designated service for streaming - I feel like the quality is high, that’s why I think it’s quick.&rdquo;</p>
                      <p className="quoteAuthor">&mdash; Genzo</p>
                    </div>
                  </div>
                </div>
              </section>{/* subsection end */}

            </section>{/* section end */}



            <section id="intro__conclusion" className="s s--sideBySide">
              <div className="s__inner">
                <div className="s__heading">
                  <h1>So what can Spotify do to better connect and grow with listeners in Japan?</h1>
                </div>
                <div className="col col--content">
                  <p>Let’s dive in to our research findings to explore ways we can drive deeper engagement.</p>
                </div>
                <div className="col col--content">
                  <Link to="/themes"><h4>Themes &amp; Insights</h4></Link>
                  <Button to="/themes" label="Go" type="forward" />
                </div>
              </div>
            </section>

          </main>
        </div>
      </Layout>
    );
  }
}
